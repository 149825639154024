<template>
  <page-view ref="pageView" :title="title" left-arrow>
    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      <van-cell-group class="mt-10">
        <!-- <van-field v-model="form.ywlx" name="ywlx" label="业务类型" placeholder="请选择业务类型"
          :rules="[{ required: true, message: '请选择业务类型' }]" /> -->
        <ywfl-select 
          required
          v-model="ywlxArr" 
          name="ywlx" 
          label="业务类型" 
          placeholder="请选择业务类型"
          title="选择业务类型"
          exclude="54"
          :rules="[{ required: true, message: '请选择业务类型' }]"
        />
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-field required v-model="jbxxForm.yhmc" name="yhmc" label="用户名称" placeholder=""
          :rules="[{ required: true, message: '请输入用户名称' }]" />
        <van-field required v-model="jbxxForm.yhdz" name="yhdz" label="用电地址" placeholder=""
          :rules="[{ required: true, message: '请输入用电地址' }]" />
        <van-field required v-model="jbxxForm.zjbh" name="zjbh" label="身份证号" placeholder=""
          :rules="[{ required: true, message: '请输入身份证号' }]" />
        <van-field required v-model="jbxxForm.lxr" name="lxr" label="联系人" placeholder=""
          :rules="[{ required: true, message: '请输入联系人' }]" />
        <van-field required v-model="jbxxForm.lxsj" name="lxsj" label="联系手机" placeholder=""
          :rules="[{ required: true, message: '请输入联系手机' }]" />
        <van-field v-model="jbxxForm.lxdh" name="lxdh" label="联系电话" placeholder=""/>
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-field name="nsbz" label="纳税标志" input-align="right">
          <template #input>
            <van-switch v-model="jbxxForm.nsbz" size="20" active-value="1" inactive-value="0" :disabled="nsbzSwitchDisabled"/>
          </template>
        </van-field>
        <div v-if="jbxxForm.nsbz == '1'">
          <van-field required v-model="nsxxForm.nsrmc" name="nsrmc" label="纳税人名称" placeholder=""
            :rules="[{ required: true, message: '请输入纳税人名称' }]" />
          <van-field required v-model="nsxxForm.nsrsbh" name="nsrsbh" label="纳税人识别号" placeholder=""
            :rules="[{ required: true, message: '请输入纳税识别号' }]" />
          <van-field required v-model="nsxxForm.nsrdz" name="nsrdz" label="纳税人地址" placeholder=""
            :rules="[{ required: true, message: '请输入纳税地址' }]" />
          <van-field required v-model="nsxxForm.nsrdh" name="nsrdh" label="纳税人电话" placeholder=""
            :rules="[{ required: true, message: '请输入纳税人电话' }]" />
          <van-field required v-model="nsxxForm.nsrkhh" name="nsrkhh" label="纳税人开户行" placeholder=""
            :rules="[{ required: true, message: '请输入纳税人开户行' }]" />
          <van-field required v-model="nsxxForm.nsryhzh" name="nsryhzh" label="纳税人银行账号" placeholder=""
            :rules="[{ required: true, message: '请输入纳税人银行账号' }]" />
          
        </div>
      </van-cell-group>

      <van-cell-group class="mt-10">
        <van-cell title="附件" class="van-cell--gzdfj">
          <gzd-fj-uploader ref="fjUploader" :gzdxx="gzdxx" :yhbh="jbxxForm.yhbh" :gnid="gnid"/>
        </van-cell>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">{{submitBtnText}}</van-button>
      </div>
    </van-form>
  </page-view>
</template>

<script>
  import { mapGetters } from 'vuex';
  import YwflSelect from '@/components/YwflSelect';  
  import GzdFjUploader from '@/components/GzdFjUploader';
  // API
  import { gzdxxService_startTask , gzdxxService_GetQuXianListByGnid } from '@/api/psdmsgdgl/service/GzdxxServiceAPI';
  import { yhYwJbxx_add, yhYwJbxx_update} from '@/api/psdmsykgl/dawhschema/YhYwJbxxAPI';
  import {yhYwNsxx_add,yhYwNsxx_remove,yhYwNsxx_update } from '@/api/psdmsykgl/dawhschema/YhYwNsxxAPI';
  export default {
    name: 'Ywbl',
    components: {
      YwflSelect,
      GzdFjUploader
    },
    data() {
      return {
        title: '用电新装申请',
        gnid: '1021',
        ywlxArr: ['5','52','521'],
        gzdxx: {
          qdrq: '',
          ywbh: ''
        },
        jbxxForm: {

        },
        nsxxForm: {

        },
        hasNsxx: false,
        nsbzSwitchDisabled: null
      }
    },
    computed: {
      ...mapGetters(['jgbm','dybm','czyh','dlzh']),
      submitBtnText()  {
        return this.jbxxForm.yhbh ? "保 存" : "提 交"
      }
    },
    methods: {
      pageSetup() {
        // TODO: 获取工单信息
      },
      onSubmit(values) {
        if (this.gzdxx.qdrq && this.gzdxx.ywbh) {
          if (this.jbxxForm.yhbh) {
            this.updateYhJbxx();
          } else {
            this.addYhjbxx();
          }
        } else {
          this.createGzd();
        }
      },
      onFailed({
        values,
        errors
      }) {
        console.log('failed:, Errors:', errors)
        this.$notify({
          type: 'danger',
          message: errors[0].message
        });
      },
      // 新建工单
      createGzd() {
        let [ywfl, ywlb, ywzl] = this.ywlxArr;
        let gzdsm = "微信-用电新装"
        let bzsm = '';
        let params = {
          ywfl,
          ywlb,
          ywzl,
          jgbm: this.jgbm,
          dybm: this.dybm || this.$conf.DEFAULT_DYBM,
          gnid: this.gnid,
          gzdsm: gzdsm,
          jypz: bzsm,

          sdrid: this.czyh,
          czry: this.czyh,
          cjry: this.czyh
        };
        
        let loading = this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        gzdxxService_GetQuXianListByGnid(this.jgbm, this.gnid).then(response => {
          gzdxxService_startTask(params, {
            user: this.czyh,
            roleList: response.content
          }).then(res => {
            this.gzdxx = res.content.data;
            loading.clear();
            this.addYhjbxx();
          }).catch(err => {
            loading.clear();
            this.$toast.fail(err.message);
          });
        }).catch(err => {
          loading.clear();
          this.$toast.fail(err.message);
        })
      },
      // 添加用户基本信息
      addYhjbxx() {
        // 字段： 登录账号(dlzh): wx-openid
        let params = {...this.jbxxForm};
        Object.assign(params, {
          qdrq: this.gzdxx.qdrq,
          ywbh: this.gzdxx.ywbh,
          jgbm: this.jgbm,
          dybm: this.dybm || this.$conf.DEFAULT_DYBM,
          ywfl: this.gzdxx.ywfl,
          ywlb: this.gzdxx.ywlb,
          yyqd: '1',
          dlzh: this.dlzh,
          czry: this.czyh,
          cjry: this.czyh,
        });

        let loading = this.$toast.loading({
          duration: 0,
          forbidClick: true
        })
        yhYwJbxx_add(params).then(res => {
          this.jbxxForm = res.content;
          loading.close();
          setTimeout(() => {
            this.$refs['fjUploader'].uploadFileList();
          }, 500);
          if (this.jbxxForm.nsbz == '1') {
            this.addYhNsxx();
          } else {
            this.$toast.success('申请已提交')
          }
        }).catch(err => {
          loading.close();
          this.$toast.fail({
            message: '用户信息新增失败',
            icon: 'none'
          });
        });
      },
      // 更新用户基本信息
      updateYhJbxx() {
        let params = {...this.jbxxForm};
        let loading = this.$toast.loading({
          forbidClick: true,
          duration: 0
        })

        setTimeout(() => {
          this.$refs['fjUploader'].uploadFileList();
        }, 500);
        yhYwJbxx_update(params).then(res => {
          loading.clear();
          if (this.jbxxForm.nsbz == '1') {
            if (this.hasNsxx) {
              this.updateYhNsxx();
            } else {
              this.addYhNsxx();
            }
          } else {
            this.removeYhNsxx();
          }
        }).catch(err => {
          loading.clear();
          this.$toast.fail('用户信息更新失败')
        });
      },
      // 添加用户纳税信息
      addYhNsxx() {
        let params = {...this.nsxxForm};
        Object.assign(params, {
          qdrq: this.gzdxx.qdrq,
          ywbh: this.gzdxx.ywbh,
          jgbm: this.jbxxForm.jgbm,
          dybm: this.jbxxForm.dybm,
          fqbm: this.jbxxForm.fqbm,
          yhbh: this.jbxxForm.yhbh,
          czry: this.jbxxForm.czry,
          czsj: this.jbxxForm.czsj,
          cjry: this.jbxxForm.cjry,
          cjsj: this.jbxxForm.cjsj,
          ywlx: this.jbxxForm.ywlx,
        })
        let loading = this.$toast.loading({
          duration: 0,
          forbidClick: true
        })
        yhYwNsxx_add(params).then(res=>{
          this.hasNsxx = true;
          loading.close();
          this.$toast.success('申请已提交')
        }).catch(err => {
          loading.close();
          this.$toast.fail({
            message: '纳税信息新增失败',
            icon: 'none'
          });
        });
      },
      // 更新用户纳税信息
      updateYhNsxx() {
        let params = {...this.nsxxForm};
        Object.assign(params, {
          qdrq: this.gzdxx.qdrq,
          ywbh: this.gzdxx.ywbh,

          jgbm: this.jbxxForm.jgbm,
          dybm: this.jbxxForm.dybm,
          fqbm: this.jbxxForm.fqbm,
          yhbh: this.jbxxForm.yhbh,
          czry: this.jbxxForm.czry,
          czsj: this.jbxxForm.czsj,
        })
        yhYwNsxx_update(params).then(response=>{
          this.$toast.success('纳税信息修改成功');
        })
      },
      // 删除用户纳税信息
      removeYhNsxx() {
        let params = {...this.nsxxForm};
        Object.assign(params, {
          qdrq: this.gzdxx.qdrq,
          ywbh: this.gzdxx.ywbh,
          jgbm: this.jbxxForm.jgbm,
          yhbh: this.jbxxForm.yhbh
        })
        yhYwNsxx_remove(params).then(response=>{
          this.hasNsxx = false;
          console.log("用户纳税信息删除");
        });
      }
    },
    watch: {
      ywlxArr: {
        handler(val) {
          if (val && val[2] && val[2] == '511') {
            this.$set(this.jbxxForm, 'nsbz', '1');
            this.nsbzSwitchDisabled = true;
          } else {
            this.$set(this.jbxxForm, 'nsbz', '0');
            this.nsbzSwitchDisabled = false;
          }
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {
      this.pageSetup();
    },
  }
</script>

<style lang="scss" scoped>
   .van-cell--gzdfj /deep/ {
    .van-cell__title {
      flex-basis: 6.2em;
      flex-grow: 0;
    }
  }
</style>