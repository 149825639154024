// WaGen___HASHKEY__3882318e_2020-04-27 10:27:04 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
业扩用户纳税信息
*/
/* ---
标准CRUD方法：添加记录
*/
export function yhYwNsxx_add(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/add',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwNsxx_update(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/update',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwNsxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/remove',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function yhYwNsxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveDelete',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function yhYwNsxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveUpdate',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function yhYwNsxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveAdd',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwNsxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/fetch',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function yhYwNsxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/fetchAll',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhYwNsxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveGet',
		method : 'post',
		data : {
			param : entity, //YhYwNsxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhYwNsxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function yhYwNsxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/selectiveCount',
		method : 'post',
		data : {
			param : entity //YhYwNsxx
		}
	})
}

/* ---
未提供注释
*/
export function yhYwNsxx_insertYhNsxxLsFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/insertYhNsxxLsFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwNsxx_deleteYhNsxxFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/deleteYhNsxxFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwNsxx_insertYhNsxxFromYw(czyh,qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/insertYhNsxxFromYw',
		method : 'post',
		data : {
			param : {
				czyh : czyh, //String
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwNsxx_insertYhYwNsxxLsFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/insertYhYwNsxxLsFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwNsxx_insertYwNsxxFromYh(qdrq,ywbh,czyh,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/insertYwNsxxFromYh',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				czyh : czyh, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function yhYwNsxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/insertList',
		method : 'post',
		data : {
			param : list //List<YhYwNsxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwNsxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/updateList',
		method : 'post',
		data : {
			param : list //List<YhYwNsxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwNsxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwNsxx/deleteList',
		method : 'post',
		data : {
			param : list //List<YhYwNsxx>
		}
	})
}

